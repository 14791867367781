
div .pict{
    display: grid;
}

.map {
    position:absolute;
    width:80vw;
    margin: 0px auto;

}

.pict img {
    margin: 0px auto;
    width:900px;
}

.pict h1 {
    margin: 0px;
    position: absolute;
}

